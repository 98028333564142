import { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'
import { useSession } from 'next-auth/react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { trackEvent as trackClientSide } from "analytics/tracking-cs"
import { useRouter } from 'next/router'
import Link from 'next/link'


interface Props {
  userAgent?: string | undefined;
  statusCode?: number | undefined;
}

const Error: NextPage<Props> = ({ userAgent, statusCode }) => {

  const router = useRouter()

  const { data: session, status } = useSession()
  const userId = (session && session !== undefined && session?.account && Array.isArray(session?.account)) ? session?.account[0]?._id : null;

  // TRACK CLIENT SIDE ERROR
  trackClientSide(userId, '404', '404', 'Client side request 404.', router);
  


  return (
    <div className={styles.container}>
      <Head>
        <title>404 Weave</title>
      </Head>

      <main className={styles.main}>

        <Image src="/images/logos/logo-no-boarder.png" width="384" height="250" alt="Weave" />
        

        <p className={styles.description}>
          Well, we shouldn&apos;t be here...rest assured our technical team has been informed. Please try your request again.
        </p>
        <p className={styles.description}>
          <Link href="/">Return home</Link>
        </p>

        <div className={styles.grid}>
          
        </div>
      </main>

      <footer className={styles.footer}>
        <Typography >
          Powered by <span className={styles.brand}>Weave</span>
        </Typography>
      </footer>
    </div>
  )
}


// Error.getInitialProps = async ({ req, err }) => {
//   const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
//   const statusCode = req ? req.statusCode : err ? err.statusCode : 404
//   return { userAgent: userAgent, statusCode: statusCode }
// }

export default Error